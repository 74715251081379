import React from 'react'
import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom'
import { AuthProvider } from './lib/AuthContext'
import { LandingPage } from './pages/LandingPage'
import { Login } from './pages/Login'
import { SignUp } from './pages/SignUp'
import { Dashboard } from './pages/Dashboard'
import { CreateEvent } from './pages/CreateEvent'
import { ManageEvent } from './pages/ManageEvent'
import { DashboardLayout } from './layouts/DashboardLayout'
import { PublicEvent } from './pages/PublicEvent'

function App() {
  return (
    <BrowserRouter>
      <AuthProvider>
        <Routes>
          <Route path="/" element={<LandingPage />} />
          <Route path="/login" element={<Login />} />
          <Route path="/signup" element={<SignUp />} />
          <Route path="/dashboard" element={<DashboardLayout />}>
            <Route index element={<Dashboard />} />
            <Route path="create-event" element={<CreateEvent />} />
            <Route path="event/:eventId" element={<ManageEvent />} />
          </Route>
          <Route path="/event/:publicUrlId" element={<PublicEvent />} />
        </Routes>
      </AuthProvider>
    </BrowserRouter>
  )
}

export default App 