import React, { useEffect, useState } from 'react'
import ReactECharts from 'echarts-for-react'
import type { Match, Player } from '../types'

interface RankingRaceChartProps {
  matches: Match[]
  currentRound: number
}

interface PlayerRanking {
  name: string
  rankings: number[]
}

export function RankingRaceChart({ matches, currentRound }: RankingRaceChartProps) {
  const [rankings, setRankings] = useState<PlayerRanking[]>([])
  const [playerCount, setPlayerCount] = useState(0)

  useEffect(() => {
    calculateRankings()
  }, [matches, currentRound])

  const calculateRankings = () => {
    // Get all unique players
    const players = new Set<string>()
    matches.forEach(match => {
      players.add(match.team1_player1.name)
      players.add(match.team1_player2.name)
      players.add(match.team2_player1.name)
      players.add(match.team2_player2.name)
    })

    setPlayerCount(players.size)

    // Initialize player scores and rankings
    const playerScores: { [key: string]: number[] } = {}
    const playerRankings: { [key: string]: number[] } = {}
    players.forEach(player => {
      playerScores[player] = Array(currentRound + 1).fill(0)
      playerRankings[player] = Array(currentRound + 1).fill(players.size)
    })

    // Calculate cumulative scores for each round
    for (let round = 1; round <= currentRound; round++) {
      const roundMatches = matches.filter(m => m.round === round)
      
      // Calculate scores for this round
      roundMatches.forEach(match => {
        if (match.team1_score !== null && match.team2_score !== null) {
          const team1Won = match.team1_score > match.team2_score
          const courtBonus = (match.court === 1 ? 3 : 1) // Court 1 worth more points
          const winPoints = courtBonus * 2
          const losePoints = courtBonus

          // Add points to cumulative scores
          if (team1Won) {
            playerScores[match.team1_player1.name][round] = playerScores[match.team1_player1.name][round - 1] + winPoints
            playerScores[match.team1_player2.name][round] = playerScores[match.team1_player2.name][round - 1] + winPoints
            playerScores[match.team2_player1.name][round] = playerScores[match.team2_player1.name][round - 1] + losePoints
            playerScores[match.team2_player2.name][round] = playerScores[match.team2_player2.name][round - 1] + losePoints
          } else {
            playerScores[match.team1_player1.name][round] = playerScores[match.team1_player1.name][round - 1] + losePoints
            playerScores[match.team1_player2.name][round] = playerScores[match.team1_player2.name][round - 1] + losePoints
            playerScores[match.team2_player1.name][round] = playerScores[match.team2_player1.name][round - 1] + winPoints
            playerScores[match.team2_player2.name][round] = playerScores[match.team2_player2.name][round - 1] + winPoints
          }
        }
      })

      // Convert scores to rankings for this round
      const roundScores = Object.entries(playerScores)
        .map(([name, scores]) => ({ name, score: scores[round] }))
        .sort((a, b) => b.score - a.score)

      // Assign rankings (1-based, handle ties)
      let currentRank = 1
      let previousScore = roundScores[0]?.score
      roundScores.forEach(({ name, score }, index) => {
        if (score < previousScore) {
          currentRank = index + 1
          previousScore = score
        }
        playerRankings[name][round] = currentRank
      })
    }

    // Convert to array format for ECharts
    const rankingsArray = Object.entries(playerRankings).map(([name, ranks]) => ({
      name,
      rankings: ranks.slice(1) // Remove initial 0
    }))

    setRankings(rankingsArray)
  }

  const option = {
    animationDuration: 1000,
    animationEasing: 'cubicInOut',
    title: {
      text: 'Player Rankings',
      left: 'center',
      top: 10,
      textStyle: {
        fontSize: 20,
        fontWeight: 'bold'
      }
    },
    tooltip: {
      trigger: 'axis',
      axisPointer: {
        type: 'shadow'
      },
      formatter: (params: any) => {
        const round = params[0].axisValue
        let content = `<strong>${round}</strong><br/>`
        // Sort by rank for this round (lower rank = better)
        params.sort((a: any, b: any) => a.data - b.data)
        params.forEach((param: any) => {
          content += `${param.marker} ${param.seriesName}: Rank ${param.data}<br/>`
        })
        return content
      }
    },
    legend: {
      type: 'scroll',
      orient: 'vertical',
      right: 10,
      top: 50,
      bottom: 50,
      textStyle: {
        fontSize: 14
      }
    },
    grid: {
      left: '10%',
      right: '15%',
      top: '15%',
      bottom: '10%',
      containLabel: true
    },
    xAxis: {
      type: 'category',
      data: Array.from({ length: currentRound }, (_, i) => `Round ${i + 1}`),
      boundaryGap: true,
      axisLabel: {
        fontSize: 14,
        interval: 0
      }
    },
    yAxis: {
      type: 'value',
      name: 'Rank',
      nameLocation: 'middle',
      nameGap: 40,
      inverse: true,
      min: 1,
      max: playerCount,
      interval: 1,
      axisLabel: {
        fontSize: 14,
        formatter: (value: number) => `${value}`
      }
    },
    series: rankings.map(player => ({
      name: player.name,
      type: 'line',
      smooth: true,
      symbol: 'circle',
      symbolSize: 10,
      lineStyle: {
        width: 3
      },
      data: player.rankings,
      emphasis: {
        focus: 'series',
        lineStyle: {
          width: 5
        },
        symbolSize: 12
      }
    })),
    color: [
      '#5470c6', '#91cc75', '#fac858', '#ee6666',
      '#73c0de', '#3ba272', '#fc8452', '#9a60b4'
    ]
  }

  return (
    <ReactECharts 
      option={option} 
      style={{ height: '600px' }}
      notMerge={true}
    />
  )
} 