import React from 'react'
import { X } from 'lucide-react'

interface AlertProps {
  type: 'success' | 'error' | 'warning' | 'info'
  message: string
  onClose?: () => void
}

const alertStyles = {
  success: 'bg-green-50 border-green-400 text-green-700',
  error: 'bg-red-50 border-red-400 text-red-700',
  warning: 'bg-yellow-50 border-yellow-400 text-yellow-700',
  info: 'bg-blue-50 border-blue-400 text-blue-700'
}

export function Alert({ type, message, onClose }: AlertProps) {
  return (
    <div className={`fixed top-4 right-4 z-50 px-4 py-3 rounded border ${alertStyles[type]} max-w-md`}>
      <span className="block sm:inline">{message}</span>
      {onClose && (
        <button 
          onClick={onClose}
          className="absolute top-0 right-0 px-4 py-3"
        >
          <X className="h-4 w-4" />
        </button>
      )}
    </div>
  )
} 