import React, { useEffect } from 'react'
import confetti from 'canvas-confetti'
import { Trophy } from 'lucide-react'

interface WinnerAnnouncementProps {
  winnerName: string
}

export function WinnerAnnouncement({ winnerName }: WinnerAnnouncementProps) {
  useEffect(() => {
    // Initial fireworks
    const end = Date.now() + 5000
    const colors = ['#ffd700', '#ffa500', '#ff6347']

    // Create firework effect
    const firework = () => {
      confetti({
        particleCount: 100,
        spread: 70,
        origin: { y: 0.6 },
        colors: colors,
      })
    }

    // Create side cannons
    const sideCannons = () => {
      confetti({
        particleCount: 50,
        angle: 60,
        spread: 55,
        origin: { x: 0 },
        colors: colors
      })

      confetti({
        particleCount: 50,
        angle: 120,
        spread: 55,
        origin: { x: 1 },
        colors: colors
      })
    }

    // Initial burst
    firework()

    // Interval for continuous effects
    const interval = setInterval(() => {
      if (Date.now() > end) {
        return clearInterval(interval)
      }

      // Alternate between firework and side cannons
      if (Date.now() % 1000 < 500) {
        firework()
      } else {
        sideCannons()
      }
    }, 250)

    return () => clearInterval(interval)
  }, [])

  return (
    <div className="relative overflow-hidden rounded-lg">
      {/* Background with gradient animation */}
      <div className="absolute inset-0 bg-gradient-to-r from-yellow-400/20 via-yellow-500/20 to-yellow-600/20 animate-pulse" />
      
      {/* Content */}
      <div className="relative px-6 py-12 text-center">
        <div className="mb-8 relative">
          <div className="absolute inset-0 bg-gradient-radial from-yellow-200/50 to-transparent" />
          <Trophy className="h-24 w-24 text-yellow-500 mx-auto animate-bounce" />
        </div>
        
        <div className="space-y-4">
          <h2 className="text-4xl font-bold text-yellow-900 animate-fade-in">
            🎉 Tournament Champion 🎉
          </h2>
          <p className="text-3xl font-semibold text-yellow-800 mt-4 animate-slide-up">
            {winnerName}
          </p>
          <div className="mt-6 flex justify-center gap-4 animate-bounce">
            <span className="text-3xl">🏆</span>
            <span className="text-3xl animate-bounce-delayed">👑</span>
            <span className="text-3xl animate-bounce-more-delayed">🌟</span>
          </div>
        </div>
      </div>
    </div>
  )
} 