import React, { useState } from 'react'
import { useAuth } from '../lib/AuthContext'
import { supabase } from '../lib/supabase'
import { useNavigate } from 'react-router-dom'

interface PlayerInput {
  name: string;
}

export function CreateEvent() {
  const { user } = useAuth()
  const navigate = useNavigate()
  const [name, setName] = useState('')
  const [courts, setCourts] = useState(2)
  const [totalTime, setTotalTime] = useState(120)
  const [matchTime, setMatchTime] = useState(30)
  const [players, setPlayers] = useState<PlayerInput[]>([{ name: '' }])
  const [error, setError] = useState('')

  const addPlayer = () => {
    setPlayers([...players, { name: '' }])
  }

  const updatePlayer = (index: number, name: string) => {
    const newPlayers = [...players]
    newPlayers[index] = { name }
    setPlayers(newPlayers)
  }

  const removePlayer = (index: number) => {
    setPlayers(players.filter((_, i) => i !== index))
  }

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault()
    try {
      const { data: { user } } = await supabase.auth.getUser()
      if (!user) {
        navigate('/login', { 
          state: { message: 'Please sign in to create an event' } 
        })
        return
      }

      // Create event
      const { data: event, error: eventError } = await supabase
        .from('events')
        .insert([
          {
            name,
            created_by: user.id,
            courts,
            total_time: totalTime,
            match_time: matchTime,
            status: 'setup'
          }
        ])
        .select()
        .single()

      if (eventError) throw eventError

      // Add players
      const { error: playersError } = await supabase
        .from('players')
        .insert(
          players
            .filter(p => p.name.trim())
            .map(p => ({
              event_id: event.id,
              name: p.name.trim()
            }))
        )

      if (playersError) throw playersError

      navigate(`/event/${event.public_url_id}`)
    } catch (error) {
      setError('Failed to create event')
      console.error(error)
    }
  }

  return (
    <div className="min-h-screen bg-slate-50 py-12 px-4">
      <div className="max-w-3xl mx-auto">
        <div className="card">
          <div className="card-header">
            <h2 className="text-2xl font-semibold">Create Tournament Event</h2>
          </div>
          <div className="card-content">
            <form onSubmit={handleSubmit} className="space-y-6">
              <div className="space-y-2">
                <label className="text-sm font-medium">Event Name</label>
                <input
                  type="text"
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                  className="input"
                  required
                />
              </div>

              <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
                <div className="space-y-2">
                  <label className="text-sm font-medium">Number of Courts</label>
                  <input
                    type="number"
                    value={courts}
                    onChange={(e) => setCourts(parseInt(e.target.value))}
                    className="input"
                    min="1"
                    required
                  />
                </div>
                <div className="space-y-2">
                  <label className="text-sm font-medium">Total Time (minutes)</label>
                  <input
                    type="number"
                    value={totalTime}
                    onChange={(e) => setTotalTime(parseInt(e.target.value))}
                    className="input"
                    min="30"
                    step="30"
                    required
                  />
                </div>
                <div className="space-y-2">
                  <label className="text-sm font-medium">Match Time (minutes)</label>
                  <input
                    type="number"
                    value={matchTime}
                    onChange={(e) => setMatchTime(parseInt(e.target.value))}
                    className="input"
                    min="15"
                    step="15"
                    required
                  />
                </div>
              </div>

              <div className="space-y-4">
                <div className="flex justify-between items-center">
                  <label className="text-sm font-medium">Players</label>
                  <button
                    type="button"
                    onClick={addPlayer}
                    className="button"
                  >
                    Add Player
                  </button>
                </div>
                {players.map((player, index) => (
                  <div key={index} className="flex gap-2">
                    <input
                      type="text"
                      value={player.name}
                      onChange={(e) => updatePlayer(index, e.target.value)}
                      placeholder="Player name"
                      className="input flex-1"
                      required
                    />
                    {players.length > 1 && (
                      <button
                        type="button"
                        onClick={() => removePlayer(index)}
                        className="button bg-red-500 hover:bg-red-600"
                      >
                        Remove
                      </button>
                    )}
                  </div>
                ))}
              </div>

              {error && (
                <div className="rounded-md bg-red-50 p-4">
                  <p className="text-sm text-red-600">{error}</p>
                </div>
              )}

              <button type="submit" className="button w-full">
                Create Event
              </button>
            </form>
          </div>
        </div>
      </div>
    </div>
  )
} 