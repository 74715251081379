import React, { useState, useEffect } from 'react'
import { useParams, Link, useNavigate } from 'react-router-dom'
import { supabase } from '../lib/supabase'
import { TournamentVisualization } from '../components/TournamentVisualization'
import { RankingRaceChart } from '../components/RankingRaceChart'
import { TournamentStats } from '../components/TournamentStats'
import { FinalStandings } from '../components/FinalStandings'
import { Trophy, ArrowLeft } from 'lucide-react'
import { WinnerAnnouncement } from '../components/WinnerAnnouncement'
import { Event, Player, Match } from '../types'
import { useAuth } from '../lib/AuthContext'

export function PublicEvent() {
  const { publicUrlId } = useParams()
  const navigate = useNavigate()
  const { user } = useAuth()
  const [event, setEvent] = useState<Event | null>(null)
  const [players, setPlayers] = useState<Player[]>([])
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState('')
  const [matches, setMatches] = useState<Match[]>([])
  const [currentRound, setCurrentRound] = useState(0)

  useEffect(() => {
    fetchEventAndPlayers()
  }, [publicUrlId])

  const fetchEventAndPlayers = async () => {
    try {
      // Fetch event details without auth check
      const { data: eventData, error: eventError } = await supabase
        .from('events')
        .select('*')
        .eq('public_url_id', publicUrlId)
        .single()

      if (eventError) {
        console.error('Error fetching event:', eventError)
        setError('Event not found')
        return
      }

      // Fetch players
      const { data: playersData, error: playersError } = await supabase
        .from('players')
        .select('*')
        .eq('event_id', eventData.id)
        .order('name')

      if (playersError) {
        console.error('Error fetching players:', playersError)
        setError('Failed to load players')
        return
      }

      // Fetch matches
      const { data: matchesData, error: matchesError } = await supabase
        .from('matches')
        .select(`
          *,
          team1_player1:players!matches_team1_player1_fkey(*),
          team1_player2:players!matches_team1_player2_fkey(*),
          team2_player1:players!matches_team2_player1_fkey(*),
          team2_player2:players!matches_team2_player2_fkey(*)
        `)
        .eq('event_id', eventData.id)
        .order('round')
        .order('court')

      if (matchesError) {
        console.error('Error fetching matches:', matchesError)
        setError('Failed to load matches')
        return
      }

      setEvent(eventData)
      setPlayers(playersData || [])
      setMatches(matchesData || [])
      setCurrentRound(matchesData?.length ? Math.max(...matchesData.map(m => m.round)) : 0)
    } catch (error) {
      console.error('Error:', error)
      setError('Failed to load event')
    } finally {
      setLoading(false)
    }
  }

  const updatePlayerRank = async (playerId: string, rank: number) => {
    try {
      const { error } = await supabase
        .from('players')
        .update({ self_rank: rank })
        .eq('id', playerId)

      if (error) throw error

      setPlayers(players.map(p => 
        p.id === playerId ? { ...p, self_rank: rank } : p
      ))
    } catch (error) {
      console.error('Failed to update rank:', error)
    }
  }

  const handleBackClick = () => {
    if (user) {
      navigate('/dashboard')
    } else {
      navigate('/')
    }
  }

  if (loading) return <div className="min-h-screen flex items-center justify-center">Loading...</div>
  if (error) return <div className="min-h-screen flex items-center justify-center text-red-600">{error}</div>
  if (!event) return <div className="min-h-screen flex items-center justify-center">Event not found</div>

  return (
    <div className="min-h-screen bg-slate-50 py-12 px-4">
      {/* Back Navigation */}
      <div className="max-w-5xl mx-auto mb-6">
        <button
          onClick={handleBackClick}
          className="inline-flex items-center text-gray-600 hover:text-gray-900 transition-colors"
        >
          <ArrowLeft className="h-4 w-4 mr-2" />
          {user ? 'Back to Dashboard' : 'Head to the main page'}
        </button>
      </div>

      <div className="max-w-5xl mx-auto">
        <div className="card">
          <div className="card-header">
            <h2 className="text-2xl font-semibold text-center">{event?.name}</h2>
            {event?.status === 'completed' && (
              <div className="mt-4">
                <WinnerAnnouncement 
                  winnerName={players.find(p => p.id === event.winner_id)?.name || 'Unknown'}
                />
              </div>
            )}
          </div>
          <div className="card-content">
            {currentRound === 0 ? (
              // Show ranking selection if tournament hasn't started
              <div className="space-y-4">
                {players.map((player) => (
                  <div key={player.id} className="flex items-center justify-between p-4 bg-white rounded-lg border">
                    <span className="font-medium">{player.name}</span>
                    <select
                      value={player.self_rank || ''}
                      onChange={(e) => updatePlayerRank(player.id, Number(e.target.value))}
                      className="input w-24"
                    >
                      <option value="">Rank</option>
                      {Array.from({ length: players.length }, (_, i) => (
                        <option key={i + 1} value={i + 1}>
                          {i + 1}
                        </option>
                      ))}
                    </select>
                  </div>
                ))}
              </div>
            ) : (
              <div className="space-y-8">
                {/* Show Final Standings first if tournament is completed */}
                {event?.status === 'completed' && (
                  <div className="bg-white p-6 rounded-lg shadow">
                    <FinalStandings 
                      matches={matches}
                      players={players}
                    />
                  </div>
                )}

                {/* Rankings Race Chart */}
                <div className="bg-white p-6 rounded-lg shadow">
                  <RankingRaceChart 
                    matches={matches}
                    currentRound={currentRound}
                  />
                </div>
                
                {/* Tournament Stats */}
                <div className="bg-white p-6 rounded-lg shadow">
                  <h3 className="text-xl font-semibold mb-4">Tournament Statistics</h3>
                  <TournamentStats 
                    matches={matches}
                    players={players}
                  />
                </div>
                
                {/* Tournament Visualization */}
                <div className="bg-white p-6 rounded-lg shadow">
                  <h3 className="text-xl font-semibold mb-4">Match Results</h3>
                  <TournamentVisualization 
                    matches={matches}
                    currentRound={currentRound}
                  />
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  )
} 