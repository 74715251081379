import React from 'react'
import { Link } from 'react-router-dom'

export function LandingPage() {
  return (
    <div className="min-h-screen bg-gradient-to-br from-blue-50 via-white to-yellow-50">
      {/* Hero Section */}
      <div className="relative overflow-hidden">
        {/* Fun background elements */}
        <div className="absolute inset-0 overflow-hidden">
          <div className="absolute -top-20 -right-20 w-40 h-40 bg-yellow-200 rounded-full mix-blend-multiply filter blur-xl opacity-70 animate-blob"></div>
          <div className="absolute top-40 -left-20 w-40 h-40 bg-blue-200 rounded-full mix-blend-multiply filter blur-xl opacity-70 animate-blob animation-delay-2000"></div>
          <div className="absolute -bottom-20 left-1/2 w-40 h-40 bg-pink-200 rounded-full mix-blend-multiply filter blur-xl opacity-70 animate-blob animation-delay-4000"></div>
        </div>

        <div className="relative">
          <div className="max-w-7xl mx-auto pt-20 pb-16 px-4 sm:pt-24 sm:pb-24 sm:px-6 lg:px-8">
            <div className="text-center">
              <h1 className="mt-4 text-4xl tracking-tight font-extrabold text-gray-900 sm:text-5xl md:text-6xl">
                <span className="block">Casual Padel</span>
                <span className="block text-blue-600">Made Awesome</span>
              </h1>
              <p className="mt-3 max-w-md mx-auto text-base text-gray-500 sm:text-lg md:mt-5 md:text-xl md:max-w-3xl">
                Get together with friends, play some padel, have a blast!
              </p>

              <div className="mt-8 max-w-sm mx-auto sm:max-w-none sm:flex sm:justify-center gap-4">
                <Link
                  to="/signup"
                  className="w-full sm:w-auto inline-flex items-center justify-center px-8 py-3 border-2 border-transparent text-base font-medium rounded-full text-white bg-blue-600 hover:bg-blue-700 hover:scale-105 transform transition duration-200 ease-in-out shadow-lg"
                >
                  Get Started
                </Link>
                <Link
                  to="/login"
                  className="mt-3 sm:mt-0 w-full sm:w-auto inline-flex items-center justify-center px-8 py-3 border-2 border-blue-600 text-base font-medium rounded-full text-blue-600 bg-transparent hover:bg-blue-50 hover:scale-105 transform transition duration-200 ease-in-out"
                >
                  Sign In
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* How it Works Section */}
      <div className="py-16 bg-white/80 backdrop-blur-sm">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="text-center">
            <h2 className="text-3xl font-bold text-gray-900">
              How it Works
            </h2>
          </div>

          <div className="mt-12">
            <div className="grid grid-cols-1 gap-8 md:grid-cols-3">
              <div className="heart-container bg-blue-100 hover:bg-blue-200">
                <h3 className="text-lg font-semibold text-center">Create Tournament</h3>
              </div>

              <div className="heart-container bg-yellow-100 hover:bg-yellow-200">
                <h3 className="text-lg font-semibold text-center">Play Matches</h3>
              </div>

              <div className="heart-container bg-green-100 hover:bg-green-200">
                <h3 className="text-lg font-semibold text-center">Have Fun!</h3>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Fun Quote Section */}
      <div className="py-16 bg-gradient-to-t from-blue-50 to-transparent">
        <div className="max-w-3xl mx-auto px-4 sm:px-6 lg:px-8 text-center">
          <div className="relative">
            <p className="text-xl font-medium text-gray-900 italic">
              "Who needs pro tournaments when you can have this much fun with friends?"
            </p>
            <p className="mt-2 text-gray-500">- Happy Padel Player</p>
          </div>
        </div>
      </div>

      {/* Final CTA */}
      <div className="bg-gradient-to-b from-blue-500 to-blue-600 transform skew-y-1">
        <div className="max-w-2xl mx-auto text-center py-16 px-4 sm:py-20 sm:px-6 lg:px-8 transform -skew-y-1">
          <h2 className="text-3xl font-extrabold text-white sm:text-4xl">
            <span className="block">Ready to Play?</span>
          </h2>
          <p className="mt-4 text-lg leading-6 text-blue-100">
            It's free, fun, and takes less than a minute to get started!
          </p>
          <Link
            to="/signup"
            className="mt-8 w-full sm:w-auto inline-flex items-center justify-center px-8 py-3 border-2 border-transparent text-base font-medium rounded-full text-blue-600 bg-white hover:bg-blue-50 hover:scale-105 transform transition duration-200 ease-in-out"
          >
            Let's Get Started
          </Link>
        </div>
      </div>
    </div>
  )
} 