import React, { useMemo } from 'react'
import { Match, Player } from '../types'

interface TournamentStatsProps {
  matches: Match[]
  players: Player[]
}

interface PlayerStats {
  name: string
  matchesWon: number
  pointsWon: number
  predictedRank: number | null
  actualRank: number
  rankingAccuracy: number
}

export function TournamentStats({ matches, players }: TournamentStatsProps) {
  const stats = useMemo(() => {
    // Initialize stats for each player
    const playerStats: { [key: string]: PlayerStats } = {}
    players.forEach(player => {
      playerStats[player.name] = {
        name: player.name,
        matchesWon: 0,
        pointsWon: 0,
        predictedRank: player.self_rank,
        actualRank: 0,
        rankingAccuracy: 0
      }
    })

    // Calculate matches won and points scored
    matches.forEach(match => {
      if (match.team1_score === null || match.team2_score === null) return

      const team1Won = match.team1_score > match.team2_score
      
      // Add points
      playerStats[match.team1_player1.name].pointsWon += match.team1_score
      playerStats[match.team1_player2.name].pointsWon += match.team1_score
      playerStats[match.team2_player1.name].pointsWon += match.team2_score
      playerStats[match.team2_player2.name].pointsWon += match.team2_score

      // Add match wins
      if (team1Won) {
        playerStats[match.team1_player1.name].matchesWon++
        playerStats[match.team1_player2.name].matchesWon++
      } else {
        playerStats[match.team2_player1.name].matchesWon++
        playerStats[match.team2_player2.name].matchesWon++
      }
    })

    // Calculate final rankings based on points
    const sortedPlayers = Object.values(playerStats)
      .sort((a, b) => b.pointsWon - a.pointsWon)
    
    // Assign actual ranks
    sortedPlayers.forEach((player, index) => {
      playerStats[player.name].actualRank = index + 1
    })

    // Calculate ranking accuracy (difference between predicted and actual)
    Object.values(playerStats).forEach(player => {
      if (player.predictedRank) {
        player.rankingAccuracy = Math.abs(player.predictedRank - player.actualRank)
      }
    })

    return playerStats
  }, [matches, players])

  // Get top performers
  const topScorer = Object.values(stats)
    .sort((a, b) => b.pointsWon - a.pointsWon)[0]

  const topWinner = Object.values(stats)
    .sort((a, b) => b.matchesWon - a.matchesWon)[0]

  const bestPredictor = Object.values(stats)
    .filter(p => p.predictedRank !== null)
    .sort((a, b) => a.rankingAccuracy - b.rankingAccuracy)[0]

  return (
    <div className="grid gap-4 md:grid-cols-3">
      {/* Top Scorer */}
      <div className="bg-white p-4 rounded-lg shadow-sm border">
        <h3 className="text-sm font-medium text-gray-500 mb-1">Most Points Scored</h3>
        <div className="flex justify-between items-baseline">
          <p className="text-2xl font-bold">{topScorer.name}</p>
          <p className="text-lg font-semibold text-blue-600">{topScorer.pointsWon} pts</p>
        </div>
      </div>

      {/* Most Matches Won */}
      <div className="bg-white p-4 rounded-lg shadow-sm border">
        <h3 className="text-sm font-medium text-gray-500 mb-1">Most Matches Won</h3>
        <div className="flex justify-between items-baseline">
          <p className="text-2xl font-bold">{topWinner.name}</p>
          <p className="text-lg font-semibold text-green-600">{topWinner.matchesWon} wins</p>
        </div>
      </div>

      {/* Best Predictor */}
      <div className="bg-white p-4 rounded-lg shadow-sm border">
        <h3 className="text-sm font-medium text-gray-500 mb-1">Most Accurate Prediction</h3>
        <div className="flex justify-between items-baseline">
          <p className="text-2xl font-bold">{bestPredictor.name}</p>
          <div className="text-right">
            <p className="text-sm text-gray-500">Predicted: {bestPredictor.predictedRank}</p>
            <p className="text-sm text-gray-500">Actual: {bestPredictor.actualRank}</p>
          </div>
        </div>
      </div>

      {/* Detailed Stats Table */}
      <div className="md:col-span-3">
        <div className="overflow-x-auto">
          <table className="w-full text-sm">
            <thead>
              <tr className="border-b">
                <th className="text-left py-2">Player</th>
                <th className="text-right py-2">Points</th>
                <th className="text-right py-2">Matches Won</th>
                <th className="text-right py-2">Predicted Rank</th>
                <th className="text-right py-2">Final Rank</th>
                <th className="text-right py-2">Accuracy</th>
              </tr>
            </thead>
            <tbody>
              {Object.values(stats)
                .sort((a, b) => b.pointsWon - a.pointsWon)
                .map(player => (
                  <tr key={player.name} className="border-b">
                    <td className="py-2">{player.name}</td>
                    <td className="text-right">{player.pointsWon}</td>
                    <td className="text-right">{player.matchesWon}</td>
                    <td className="text-right">{player.predictedRank || '-'}</td>
                    <td className="text-right">{player.actualRank}</td>
                    <td className="text-right">{player.predictedRank 
                      ? `${player.rankingAccuracy} off`
                      : '-'}</td>
                  </tr>
                ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  )
} 