import React, { useState, useEffect } from 'react'
import { useParams, useNavigate } from 'react-router-dom'
import { supabase } from '../lib/supabase'
import { Pencil, Save, X } from 'lucide-react'
import { Alert } from '../components/ui/alert'
import { Event, Player, Match, MatchCreate, EditingScores } from '../types'

export function ManageEvent() {
  const { eventId } = useParams()
  const navigate = useNavigate()
  const [event, setEvent] = useState<Event | null>(null)
  const [players, setPlayers] = useState<Player[]>([])
  const [matches, setMatches] = useState<Match[]>([])
  const [currentRound, setCurrentRound] = useState(1)
  const [loading, setLoading] = useState(true)
  const [editingMatches, setEditingMatches] = useState<string[]>([])
  const [editScores, setEditScores] = useState<EditingScores>({})
  const [error, setError] = useState<string | null>(null)
  const [showConfirm, setShowConfirm] = useState(false)
  const [pendingAction, setPendingAction] = useState<number | null>(null)
  const [showScoreWarning, setShowScoreWarning] = useState(false)
  const [pendingScores, setPendingScores] = useState<{
    roundNumber: number;
    scores: EditingScores;
  } | null>(null)
  const [alert, setAlert] = useState<{
    type: 'success' | 'error' | 'warning' | 'info';
    message: string;
  } | null>(null)

  useEffect(() => {
    fetchEventData()
  }, [eventId])

  useEffect(() => {
    // After fetching matches, initialize editing state for new matches
    const matchesWithoutScores = matches.filter(m => !hasScores(m))
    if (matchesWithoutScores.length > 0) {
      setEditingMatches(matchesWithoutScores.map(m => m.id))
      const initialScores = matchesWithoutScores.reduce((acc, match) => ({
        ...acc,
        [match.id]: {
          team1Score: 0,
          team2Score: 0
        }
      }), {})
      setEditScores(prev => ({ ...prev, ...initialScores }))
    }
  }, [matches])

  const fetchEventData = async () => {
    try {
      // Fetch event details
      const { data: eventData, error: eventError } = await supabase
        .from('events')
        .select('*')
        .eq('id', eventId)
        .single()

      if (eventError) {
        if (eventError.code === 'PGRST301') {
          navigate('/login', { 
            state: { message: 'Please sign in to view this event' } 
          })
          return
        }
        throw eventError
      }

      // Only allow access if event is public or user is owner
      const { data: { user } } = await supabase.auth.getUser()
      if (eventData.status === 'setup' && eventData.created_by !== user?.id) {
        navigate('/', { 
          state: { message: 'You do not have permission to view this event' } 
        })
        return
      }

      // Fetch players with rankings
      const { data: playersData } = await supabase
        .from('players')
        .select('*')
        .eq('event_id', eventId)
        .order('self_rank', { ascending: true, nullsFirst: false })

      // Fetch existing matches
      const { data: matchesData } = await supabase
        .from('matches')
        .select(`
          *,
          team1_player1:players!matches_team1_player1_fkey(*),
          team1_player2:players!matches_team1_player2_fkey(*),
          team2_player1:players!matches_team2_player1_fkey(*),
          team2_player2:players!matches_team2_player2_fkey(*)
        `)
        .eq('event_id', eventId)
        .order('round')
        .order('court')

      setEvent(eventData)
      setPlayers(playersData || [])
      setMatches(matchesData || [])
      setCurrentRound(matchesData?.length ? Math.max(...matchesData.map(m => m.round)) : 1)
      setLoading(false)
    } catch (error) {
      console.error('Error fetching event data:', error)
      setError('Failed to load event')
    }
  }

  const generateFirstRoundMatches = async () => {
    if (!eventId || !event?.courts) return;

    const matchesToCreate: MatchCreate[] = []

    const rankedPlayers = [...players].sort((a, b) => {
      if (a.self_rank === null) return 1
      if (b.self_rank === null) return -1
      return a.self_rank - b.self_rank
    })

    // For 8 players on 2 courts example:
    // Court 1: [1,3] vs [2,4]
    // Court 2: [5,7] vs [6,8]
    for (let court = 1; court <= event.courts; court++) {
      const baseIndex = (court - 1) * 4
      const team1 = [rankedPlayers[baseIndex], rankedPlayers[baseIndex + 2]]
      const team2 = [rankedPlayers[baseIndex + 1], rankedPlayers[baseIndex + 3]]

      if (team1[0] && team1[1] && team2[0] && team2[1]) {
        matchesToCreate.push({
          event_id: eventId,
          round: 1,
          court,
          team1_player1: team1[0].id,
          team1_player2: team1[1].id,
          team2_player1: team2[0].id,
          team2_player2: team2[1].id
        })
      }
    }

    try {
      const { data: newMatches, error } = await supabase
        .from('matches')
        .insert(matchesToCreate)
        .select(`
          *,
          team1_player1:players!matches_team1_player1_fkey(*),
          team1_player2:players!matches_team1_player2_fkey(*),
          team2_player1:players!matches_team2_player1_fkey(*),
          team2_player2:players!matches_team2_player2_fkey(*)
        `)

      if (error) throw error
      setMatches([...matches, ...newMatches])
    } catch (error) {
      console.error('Error generating matches:', error)
    }
  }

  const updateMatchScore = async (matchId: string, team1Score: number, team2Score: number) => {
    try {
      const { error } = await supabase
        .from('matches')
        .update({ team1_score: team1Score, team2_score: team2Score })
        .eq('id', matchId)

      if (error) throw error

      setMatches(matches.map(match => 
        match.id === matchId 
          ? { ...match, team1_score: team1Score, team2_score: team2Score }
          : match
      ))
    } catch (error) {
      console.error('Error updating match score:', error)
    }
  }

  const regenerateRound = (roundNumber: number) => {
    setShowConfirm(true)
    setPendingAction(roundNumber)
  }

  const handleRegenerateConfirm = async () => {
    if (!pendingAction) return

    try {
      // Delete all matches for this round and subsequent rounds
      const { error: deleteError } = await supabase
        .from('matches')
        .delete()
        .eq('event_id', eventId)
        .gte('round', pendingAction)

      if (deleteError) throw deleteError

      // Remove the deleted matches from local state immediately
      const updatedMatches = matches.filter(m => m.round < pendingAction)
      setMatches(updatedMatches)
      setCurrentRound(pendingAction - 1)

      if (pendingAction === 1) {
        await generateFirstRoundMatches()
      } else {
        const previousRoundMatches = updatedMatches.filter(m => m.round === pendingAction - 1)
        const previousRoundComplete = previousRoundMatches.every(
          m => m.team1_score !== null && m.team2_score !== null
        )

        if (!previousRoundComplete) {
          setError('Please complete all matches in the previous round first')
          return
        }

        await generateNextRound(pendingAction)
      }
    } catch (error) {
      console.error('Error regenerating round:', error)
      setError('Failed to regenerate round')
    } finally {
      setShowConfirm(false)
      setPendingAction(null)
    }
  }

  const determineMatchWinners = (match: Match): { winners: Player[]; losers: Player[] } => {
    // If it's a draw (0-0), randomly decide winners
    if (match.team1_score === 0 && match.team2_score === 0) {
      const isTeam1Winner = Math.random() < 0.5
      return isTeam1Winner 
        ? {
            winners: [match.team1_player1, match.team1_player2],
            losers: [match.team2_player1, match.team2_player2]
          }
        : {
            winners: [match.team2_player1, match.team2_player2],
            losers: [match.team1_player1, match.team1_player2]
          }
    }

    // Normal case where there's a clear winner
    const team1Won = match.team1_score! > match.team2_score!
    return team1Won
      ? {
          winners: [match.team1_player1, match.team1_player2],
          losers: [match.team2_player1, match.team2_player2]
        }
      : {
          winners: [match.team2_player1, match.team2_player2],
          losers: [match.team1_player1, match.team1_player2]
        }
  }

  const generateNextRound = async (roundNumber?: number) => {
    if (!eventId || !event?.courts) return;
    const targetRound = roundNumber || currentRound + 1
    const previousRound = targetRound - 1
    const previousRoundMatches = matches.filter(m => m.round === previousRound)
    
    if (previousRoundMatches.length === 0) {
      setError('No matches found in the previous round')
      return
    }

    const allMatchesScored = previousRoundMatches.every(
      m => m.team1_score !== null && m.team2_score !== null
    )
    
    if (!allMatchesScored) {
      setError('Please complete all matches in the previous round first')
      return
    }

    const nextRound = targetRound
    const matchesToCreate: MatchCreate[] = []
    
    // Get all players and their results
    const allPlayers = previousRoundMatches.flatMap(match => {
      const result = determineMatchWinners(match)
      return [
        ...result.winners.map(p => ({ ...p, isWinner: true, court: match.court })),
        ...result.losers.map(p => ({ ...p, isWinner: false, court: match.court }))
      ]
    })

    // Sort players by court and result
    const sortedPlayers = allPlayers.sort((a, b) => {
      if (a.isWinner === b.isWinner) {
        return a.court - b.court
      }
      return a.isWinner ? -1 : 1
    })

    // Create matches for each court
    for (let courtNum = 1; courtNum <= event.courts; courtNum++) {
      const startIdx = (courtNum - 1) * 4
      const playersForCourt = sortedPlayers.slice(startIdx, startIdx + 4)

      if (playersForCourt.length === 4) {
        // Shuffle players to ensure different partnerships
        const shuffledPlayers = shuffleArray(playersForCourt)
        
        matchesToCreate.push({
          event_id: eventId!,
          round: nextRound,
          court: courtNum,
          team1_player1: shuffledPlayers[0].id,
          team1_player2: shuffledPlayers[1].id,
          team2_player1: shuffledPlayers[2].id,
          team2_player2: shuffledPlayers[3].id
        })
      }
    }

    try {
      const { data: newMatches, error } = await supabase
        .from('matches')
        .insert(matchesToCreate)
        .select(`
          *,
          team1_player1:players!matches_team1_player1_fkey(*),
          team1_player2:players!matches_team1_player2_fkey(*),
          team2_player1:players!matches_team2_player1_fkey(*),
          team2_player2:players!matches_team2_player2_fkey(*)
        `)

      if (error) throw error
      setMatches([...matches, ...newMatches])
      setCurrentRound(nextRound)
    } catch (error) {
      console.error('Error generating next round:', error)
    }
  }

  // Helper function to shuffle array
  const shuffleArray = <T,>(array: T[]): T[] => {
    const newArray = [...array]
    for (let i = newArray.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [newArray[i], newArray[j]] = [newArray[j], newArray[i]]
    }
    return newArray
  }

  const startEditingMatch = (match: Match) => {
    setEditingMatches(prev => [...prev, match.id])
    setEditScores(prev => ({
      ...prev,
      [match.id]: {
        team1Score: match.team1_score || 0,
        team2Score: match.team2_score || 0
      }
    }))
  }

  const cancelEditingMatch = (matchId: string) => {
    setEditingMatches(prev => prev.filter(id => id !== matchId))
    setEditScores(prev => {
      const newScores = { ...prev }
      delete newScores[matchId]
      return newScores
    })
  }

  const saveMatchScore = async (matchId: string) => {
    const scores = editScores[matchId]
    if (!scores) return

    try {
      const matchToUpdate = matches.find(m => m.id === matchId)
      if (!matchToUpdate) return

      const { error } = await supabase
        .from('matches')
        .update({
          team1_score: scores.team1Score,
          team2_score: scores.team2Score
        })
        .eq('id', matchId)

      if (error) throw error

      // Update local state
      setMatches(matches.map(match => 
        match.id === matchId
          ? {
              ...match,
              team1_score: scores.team1Score,
              team2_score: scores.team2Score
            }
          : match
      ))

      // Only remove from editing state if it wasn't a new match
      if (hasScores({ ...matchToUpdate, team1_score: scores.team1Score, team2_score: scores.team2Score })) {
        setEditingMatches(prev => prev.filter(id => id !== matchId))
        setEditScores(prev => {
          const newScores = { ...prev }
          delete newScores[matchId]
          return newScores
        })
      }
    } catch (error) {
      console.error('Error updating match score:', error)
      setError('Failed to update match score')
    }
  }

  const hasScores = (match: Match) => {
    return match.team1_score !== null && 
           match.team2_score !== null && 
           (match.team1_score > 0 || match.team2_score > 0)
  }

  const handleScoreUpdate = (roundNumber: number, scores: EditingScores) => {
    if (roundNumber < currentRound) {
      setShowScoreWarning(true)
      setPendingScores({ roundNumber, scores })
    } else {
      // For current round, just save directly
      Object.keys(scores).forEach(matchId => {
        saveMatchScore(matchId)
      })
    }
  }

  const handleConfirmedScoreUpdate = async () => {
    if (!pendingScores) return

    try {
      // Save the scores for the round
      await Promise.all(
        Object.keys(pendingScores.scores).map(matchId => saveMatchScore(matchId))
      )

      // Delete all subsequent rounds
      const { error: deleteError } = await supabase
        .from('matches')
        .delete()
        .eq('event_id', eventId)
        .gt('round', pendingScores.roundNumber)

      if (deleteError) throw deleteError

      // Remove deleted matches from local state
      setMatches(matches.filter(m => m.round <= pendingScores.roundNumber))
      setCurrentRound(pendingScores.roundNumber)

      // Generate next round
      await generateNextRound(pendingScores.roundNumber + 1)
    } catch (error) {
      console.error('Error updating scores:', error)
      setError('Failed to update scores and regenerate rounds')
    } finally {
      setShowScoreWarning(false)
      setPendingScores(null)
    }
  }

  const completeTournament = async () => {
    if (!event) return;

    try {
      // Calculate final standings
      const playerStats = calculateFinalStandings();
      const winner = playerStats[0]; // First player in sorted array is the winner

      // Update event status and winner
      const { error } = await supabase
        .from('events')
        .update({ 
          status: 'completed',
          winner_id: winner.id
        })
        .eq('id', event.id)
        .select()
        .single();

      if (error) {
        console.error('Error updating event:', error);
        throw error;
      }

      // Update local state
      setEvent(prev => prev ? {
        ...prev,
        status: 'completed',
        winner_id: winner.id
      } : null);

      // Show success message
      showAlert('success', `Tournament completed! ${winner.name} is the winner!`);

      // Navigate to public view after a short delay
      setTimeout(() => {
        navigate(`/event/${event.public_url_id}`);
      }, 2000);
    } catch (error) {
      console.error('Error completing tournament:', error);
      showAlert('error', 'Failed to complete tournament');
    }
  };

  const calculateFinalStandings = () => {
    const stats: {
      id: string
      name: string
      matchesWon: number
      pointsScored: number
      predictedRank: number | null
      actualRank: number
      rankingAccuracy: number
      finalScore: number
    }[] = players.map(player => ({
      id: player.id,
      name: player.name,
      matchesWon: 0,
      pointsScored: 0,
      predictedRank: player.self_rank,
      actualRank: 0,
      rankingAccuracy: 0,
      finalScore: 0
    }))

    // Calculate matches won and points scored
    matches.forEach(match => {
      if (match.team1_score === null || match.team2_score === null) return

      const team1Won = match.team1_score > match.team2_score
      
      // Add points
      const team1Players = stats.filter(p => 
        p.id === match.team1_player1.id || p.id === match.team1_player2.id
      )
      const team2Players = stats.filter(p => 
        p.id === match.team2_player1.id || p.id === match.team2_player2.id
      )

      team1Players.forEach(p => {
        p.pointsScored += match.team1_score!
        if (team1Won) p.matchesWon++
      })

      team2Players.forEach(p => {
        p.pointsScored += match.team2_score!
        if (!team1Won) p.matchesWon++
      })
    })

    // Calculate actual rankings based on points
    const sortedByPoints = [...stats].sort((a, b) => b.pointsScored - a.pointsScored)
    sortedByPoints.forEach((player, index) => {
      const statsPlayer = stats.find(p => p.id === player.id)
      if (statsPlayer) statsPlayer.actualRank = index + 1
    })

    // Calculate ranking accuracy
    stats.forEach(player => {
      if (player.predictedRank) {
        player.rankingAccuracy = Math.abs(player.predictedRank - player.actualRank)
      }
    })

    // Calculate final scores
    const maxMatchesWon = Math.max(...stats.map(p => p.matchesWon))
    
    stats.forEach(player => {
      const matchScore = (player.matchesWon / maxMatchesWon) * 50
      const accuracyScore = player.predictedRank 
        ? ((players.length - player.rankingAccuracy) / players.length) * 50 
        : 0

      player.finalScore = matchScore + accuracyScore
    })

    // Sort by final score, then points scored as tiebreaker
    return stats.sort((a, b) => {
      if (b.finalScore !== a.finalScore) return b.finalScore - a.finalScore
      return b.pointsScored - a.pointsScored
    })
  }

  const showAlert = (type: 'success' | 'error' | 'warning' | 'info', message: string) => {
    setAlert({ type, message })
    setTimeout(() => setAlert(null), 5000) // Auto-dismiss after 5 seconds
  }

  if (loading) return <div>Loading...</div>

  return (
    <div className="space-y-6">
      <div className="flex justify-between items-center">
        <h2 className="text-2xl font-semibold">{event?.name}</h2>
        {matches.length === 0 && (
          <button 
            onClick={generateFirstRoundMatches}
            className="button"
          >
            Generate First Round
          </button>
        )}
      </div>

      {alert && (
        <Alert
          type={alert.type}
          message={alert.message}
          onClose={() => setAlert(null)}
        />
      )}

      {showConfirm && (
        <div className="fixed inset-0 bg-black/50 flex items-center justify-center z-50">
          <div className="bg-white rounded-lg p-6 max-w-md mx-4">
            <h3 className="text-lg font-medium mb-4">
              Are you sure you want to regenerate round {pendingAction}? This will delete all matches for this round and any subsequent rounds.
            </h3>
            <div className="flex justify-end gap-4">
              <button
                onClick={() => {
                  setShowConfirm(false)
                  setPendingAction(null)
                }}
                className="button bg-gray-500 hover:bg-gray-600 text-white"
              >
                Cancel
              </button>
              <button
                onClick={handleRegenerateConfirm}
                className="button bg-yellow-600 hover:bg-yellow-700 text-white"
              >
                Regenerate
              </button>
            </div>
          </div>
        </div>
      )}

      {showScoreWarning && pendingScores && (
        <div className="fixed inset-0 bg-black/50 flex items-center justify-center z-50">
          <div className="bg-white rounded-lg p-6 max-w-md mx-4">
            <h3 className="text-lg font-medium mb-4">
              Warning: Updating scores for round {pendingScores.roundNumber} will require regenerating all subsequent rounds. This will delete all matches and scores after this round.
            </h3>
            <div className="flex justify-end gap-4">
              <button
                onClick={() => {
                  setShowScoreWarning(false)
                  setPendingScores(null)
                }}
                className="button bg-gray-500 hover:bg-gray-600 text-white"
              >
                Cancel
              </button>
              <button
                onClick={handleConfirmedScoreUpdate}
                className="button bg-yellow-600 hover:bg-yellow-700 text-white"
              >
                Update and Regenerate
              </button>
            </div>
          </div>
        </div>
      )}

      <div className="space-y-8">
        {Array.from({ length: currentRound }, (_, i) => i + 1).map(round => {
          const roundMatches = matches.filter(match => match.round === round);
          const isCurrentRound = round === currentRound;
          const hasUnsavedChanges = roundMatches.some(match => 
            editScores[match.id] && (
              editScores[match.id].team1Score !== match.team1_score ||
              editScores[match.id].team2Score !== match.team2_score
            )
          );

          return (
            <div key={round} className="space-y-4">
              <div className="flex justify-between items-center">
                <h3 className="text-xl font-medium">Round {round}</h3>
                <div className="flex gap-2">
                  {hasUnsavedChanges && (
                    <button
                      onClick={() => handleScoreUpdate(round, editScores)}
                      className="button bg-green-600 hover:bg-green-700 text-white"
                    >
                      Save All Scores
                    </button>
                  )}
                  {isCurrentRound && (
                    <button
                      onClick={() => regenerateRound(round)}
                      className="button bg-yellow-600 hover:bg-yellow-700 text-white"
                    >
                      Regenerate Round
                    </button>
                  )}
                </div>
              </div>
              <div className="grid gap-4 md:grid-cols-2">
                {roundMatches.map(match => {
                  const isEditing = !hasScores(match) || editingMatches.includes(match.id);
                  return (
                    <div key={match.id} className="card">
                      <div className="card-header">
                        <div className="flex justify-between items-center">
                          <h4 className="font-medium">Court {match.court}</h4>
                          {hasScores(match) && !isEditing && (
                            <button
                              onClick={() => startEditingMatch(match)}
                              className="p-2 hover:bg-slate-100 rounded-full transition-colors"
                              title="Edit scores"
                            >
                              <Pencil className="h-5 w-5" />
                            </button>
                          )}
                        </div>
                      </div>
                      <div className="card-content">
                        <div className="flex flex-col gap-4">
                          {/* Team 1 */}
                          <div className="flex items-center gap-4">
                            <div className="flex-1">
                              <div className="font-medium">{match.team1_player1.name}</div>
                              <div className="font-medium">{match.team1_player2.name}</div>
                            </div>
                            <div className="w-16">
                              <input
                                type="number"
                                value={editScores[match.id]?.team1Score ?? match.team1_score ?? 0}
                                onChange={(e) => {
                                  const value = Math.min(99, parseInt(e.target.value) || 0);
                                  setEditScores(prev => ({
                                    ...prev,
                                    [match.id]: {
                                      ...prev[match.id],
                                      team1Score: value
                                    }
                                  }));
                                }}
                                className="input text-center text-2xl font-bold h-12 px-2"
                                min="0"
                                max="99"
                                disabled={!isCurrentRound && hasScores(match)}
                              />
                            </div>
                          </div>

                          {/* Divider */}
                          <div className="border-t border-gray-200"></div>

                          {/* Team 2 */}
                          <div className="flex items-center gap-4">
                            <div className="flex-1">
                              <div className="font-medium">{match.team2_player1.name}</div>
                              <div className="font-medium">{match.team2_player2.name}</div>
                            </div>
                            <div className="w-16">
                              <input
                                type="number"
                                value={editScores[match.id]?.team2Score ?? match.team2_score ?? 0}
                                onChange={(e) => {
                                  const value = Math.min(99, parseInt(e.target.value) || 0);
                                  setEditScores(prev => ({
                                    ...prev,
                                    [match.id]: {
                                      ...prev[match.id],
                                      team2Score: value
                                    }
                                  }));
                                }}
                                className="input text-center text-2xl font-bold h-12 px-2"
                                min="0"
                                max="99"
                                disabled={!isCurrentRound && hasScores(match)}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          );
        })}

        {/* Complete Tournament or Generate Next Round button */}
        {matches.length > 0 && (
          <div className="sticky bottom-4 flex justify-center pt-8">
            {currentRound === 4 && matches.every(match => hasScores(match)) ? (
              <button 
                onClick={completeTournament}
                className="button bg-green-600 hover:bg-green-700 text-white shadow-lg"
              >
                Complete Tournament
              </button>
            ) : currentRound < 4 ? (
              <button 
                onClick={() => generateNextRound()}
                className="button bg-blue-600 hover:bg-blue-700 text-white shadow-lg"
              >
                Generate Next Round
              </button>
            ) : (
              <div className="text-sm text-red-600">
                Please complete all matches before finishing the tournament
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  )
} 