import React, { useMemo } from 'react'
import { Match, Player } from '../types'
import { Trophy, Medal, Target } from 'lucide-react'

interface FinalStandingsProps {
  matches: Match[]
  players: Player[]
}

interface PlayerScore {
  name: string
  matchesWon: number
  pointsScored: number
  predictedRank: number | null
  actualRank: number
  rankingAccuracy: number
  finalScore: number
}

export function FinalStandings({ matches, players }: FinalStandingsProps) {
  const standings = useMemo(() => {
    // Calculate base stats
    const stats: { [key: string]: PlayerScore } = {}
    players.forEach(player => {
      stats[player.name] = {
        name: player.name,
        matchesWon: 0,
        pointsScored: 0,
        predictedRank: player.self_rank,
        actualRank: 0,
        rankingAccuracy: 0,
        finalScore: 0
      }
    })

    // Calculate matches won and points scored
    matches.forEach(match => {
      if (match.team1_score === null || match.team2_score === null) return

      const team1Won = match.team1_score > match.team2_score
      
      // Add points
      stats[match.team1_player1.name].pointsScored += match.team1_score
      stats[match.team1_player2.name].pointsScored += match.team1_score
      stats[match.team2_player1.name].pointsScored += match.team2_score
      stats[match.team2_player2.name].pointsScored += match.team2_score

      // Add match wins
      if (team1Won) {
        stats[match.team1_player1.name].matchesWon++
        stats[match.team1_player2.name].matchesWon++
      } else {
        stats[match.team2_player1.name].matchesWon++
        stats[match.team2_player2.name].matchesWon++
      }
    })

    // Calculate actual rankings based on points
    const sortedByPoints = Object.values(stats)
      .sort((a, b) => b.pointsScored - a.pointsScored)
    
    sortedByPoints.forEach((player, index) => {
      stats[player.name].actualRank = index + 1
    })

    // Calculate ranking accuracy
    Object.values(stats).forEach(player => {
      if (player.predictedRank) {
        player.rankingAccuracy = Math.abs(player.predictedRank - player.actualRank)
      }
    })

    // Calculate final scores
    const maxMatchesWon = Math.max(...Object.values(stats).map(p => p.matchesWon))
    const maxPoints = Math.max(...Object.values(stats).map(p => p.pointsScored))
    
    Object.values(stats).forEach(player => {
      // Normalize matches won and ranking accuracy to 0-50 points
      const matchScore = (player.matchesWon / maxMatchesWon) * 50
      const accuracyScore = player.predictedRank 
        ? ((players.length - player.rankingAccuracy) / players.length) * 50 
        : 0

      player.finalScore = matchScore + accuracyScore
    })

    return Object.values(stats).sort((a, b) => {
      if (b.finalScore !== a.finalScore) return b.finalScore - a.finalScore
      return b.pointsScored - a.pointsScored // Tiebreaker
    })
  }, [matches, players])

  const getIcon = (position: number) => {
    switch (position) {
      case 0:
        return <Trophy className="h-6 w-6 text-yellow-500" />
      case 1:
        return <Medal className="h-6 w-6 text-gray-400" />
      case 2:
        return <Medal className="h-6 w-6 text-amber-600" />
      default:
        return null
    }
  }

  // Find most delusional player(s) - those with biggest gap between predicted and actual rank
  const maxRankingDiff = Math.max(...standings.map(p => p.rankingAccuracy))
  const mostDelusional = standings.filter(p => p.rankingAccuracy === maxRankingDiff)
  const isOverconfident = mostDelusional[0].predictedRank! < mostDelusional[0].actualRank

  // Find biggest rank improver - comparing self_rank with final rank
  const biggestImprovement = standings.reduce((best, current) => {
    if (!current.predictedRank) return best
    const improvement = current.predictedRank - current.actualRank
    if (!best || improvement > best.improvement) {
      return { player: current, improvement }
    }
    return best
  }, null as { player: typeof standings[0], improvement: number } | null)

  return (
    <div className="space-y-6">
      {/* Winner Banner */}
      <div className="grid gap-6 md:grid-cols-3">
        {/* Tournament Champion */}
        <div className="bg-gradient-to-r from-yellow-100 to-yellow-50 border border-yellow-200 rounded-lg p-6 text-center">
          <Trophy className="h-12 w-12 text-yellow-500 mx-auto mb-2" />
          <h3 className="text-2xl font-bold text-yellow-900">
            Tournament Champion
          </h3>
          <p className="text-xl font-semibold text-yellow-800 mt-2">
            {standings[0].name}
          </p>
          <p className="text-sm text-yellow-700 mt-1">
            Final Score: {standings[0].finalScore.toFixed(1)} points
          </p>
        </div>

        {/* Most Delusional */}
        <div className="bg-gradient-to-r from-pink-100 to-pink-50 border border-pink-200 rounded-lg p-6 text-center">
          <span className="text-3xl mb-2 block">
            {isOverconfident ? '🤡' : '🙈'}
          </span>
          <h3 className="text-2xl font-bold text-pink-900">
            {isOverconfident ? 'Most Overconfident' : 'Too Humble'}
          </h3>
          {mostDelusional.map((player) => (
            <div key={player.name}>
              <p className="text-xl font-semibold text-pink-800 mt-2">
                {player.name}
              </p>
              <p className="text-sm text-pink-700 mt-1">
                {isOverconfident ? (
                  <>
                    Thought they were #{player.predictedRank} but finished #{player.actualRank}
                    <br />
                    <span className="italic">
                      "Reality is often disappointing" - Thanos
                    </span>
                  </>
                ) : (
                  <>
                    Ranked themselves #{player.predictedRank} but crushed it at #{player.actualRank}
                    <br />
                    <span className="italic">
                      "Stop hiding that talent under a rock!"
                    </span>
                  </>
                )}
              </p>
            </div>
          ))}
        </div>

        {/* Biggest Improvement */}
        {biggestImprovement && biggestImprovement.improvement > 0 && (
          <div className="bg-gradient-to-r from-green-100 to-green-50 border border-green-200 rounded-lg p-6 text-center">
            <span className="text-3xl mb-2 block">🚀</span>
            <h3 className="text-2xl font-bold text-green-900">
              Biggest Surprise
            </h3>
            <p className="text-xl font-semibold text-green-800 mt-2">
              {biggestImprovement.player.name}
            </p>
            <p className="text-sm text-green-700 mt-1">
              Ranked #{biggestImprovement.player.predictedRank} → Finished #{biggestImprovement.player.actualRank}
              <br />
              Improved by {biggestImprovement.improvement} positions
            </p>
          </div>
        )}
      </div>

      {/* Detailed Standings */}
      <div className="bg-white rounded-lg shadow overflow-hidden">
        <table className="min-w-full divide-y divide-gray-200">
          <thead className="bg-gray-50">
            <tr>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Rank</th>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Player</th>
              <th className="px-6 py-3 text-right text-xs font-medium text-gray-500 uppercase tracking-wider">Matches Won</th>
              <th className="px-6 py-3 text-right text-xs font-medium text-gray-500 uppercase tracking-wider">Points Scored</th>
              <th className="px-6 py-3 text-right text-xs font-medium text-gray-500 uppercase tracking-wider">Prediction</th>
              <th className="px-6 py-3 text-right text-xs font-medium text-gray-500 uppercase tracking-wider">Final Score</th>
            </tr>
          </thead>
          <tbody className="bg-white divide-y divide-gray-200">
            {standings.map((player, index) => (
              <tr key={player.name} className={index < 3 ? 'bg-gray-50' : ''}>
                <td className="px-6 py-4 whitespace-nowrap">
                  <div className="flex items-center">
                    {getIcon(index)}
                    <span className="ml-2">{index + 1}</span>
                  </div>
                </td>
                <td className="px-6 py-4 whitespace-nowrap font-medium">
                  {player.name}
                </td>
                <td className="px-6 py-4 whitespace-nowrap text-right">
                  {player.matchesWon}
                </td>
                <td className="px-6 py-4 whitespace-nowrap text-right">
                  {player.pointsScored}
                </td>
                <td className="px-6 py-4 whitespace-nowrap text-right">
                  {player.predictedRank ? 
                    `${player.rankingAccuracy} off` : 
                    '-'
                  }
                </td>
                <td className="px-6 py-4 whitespace-nowrap text-right font-semibold">
                  {player.finalScore.toFixed(1)}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      {/* Scoring Explanation */}
      <div className="bg-gray-50 rounded-lg p-4 text-sm text-gray-600">
        <h4 className="font-medium mb-2">Scoring System</h4>
        <ul className="list-disc list-inside space-y-1">
          <li>Match Wins: 50% of final score (normalized by max wins)</li>
          <li>Prediction Accuracy: 50% of final score (based on ranking difference)</li>
          <li>Points Scored: Used as tiebreaker</li>
        </ul>
      </div>
    </div>
  )
} 