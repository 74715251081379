import React from 'react'
import { Match } from '../types'

interface TournamentVisualizationProps {
  matches: Match[]
  currentRound: number
}

export function TournamentVisualization({ matches, currentRound }: TournamentVisualizationProps) {
  const roundMatches = matches.reduce((acc: Record<number, Match[]>, match) => {
    if (!acc[match.round]) {
      acc[match.round] = []
    }
    acc[match.round].push(match)
    return acc
  }, {})

  return (
    <div className="space-y-2">
      <h3 className="text-lg font-medium mb-4">Match Results</h3>
      {Object.entries(roundMatches).map(([round, matches]) => (
        <div key={round} className="border-b last:border-b-0">
          <div className="text-sm font-medium text-gray-500 mb-2">Round {round}</div>
          <div className="space-y-1 mb-4">
            {matches.map((match) => {
              const team1Won = match.team1_score !== null && match.team2_score !== null && 
                             match.team1_score > match.team2_score
              const team2Won = match.team1_score !== null && match.team2_score !== null && 
                             match.team2_score > match.team1_score
              
              return (
                <div key={match.id} className="text-sm grid grid-cols-[auto,1fr,auto,1fr] gap-2 items-center">
                  <div className="text-gray-500">Court {match.court}</div>
                  <div className={`text-right ${team1Won ? 'font-bold' : ''}`}>
                    {match.team1_player1.name}/{match.team1_player2.name}
                  </div>
                  <div className="text-center font-medium px-2 min-w-[60px]">
                    {match.team1_score !== null ? (
                      `${match.team1_score}-${match.team2_score}`
                    ) : (
                      '-'
                    )}
                  </div>
                  <div className={team2Won ? 'font-bold' : ''}>
                    {match.team2_player1.name}/{match.team2_player2.name}
                  </div>
                </div>
              )
            })}
          </div>
        </div>
      ))}
    </div>
  )
} 